import React, {Component} from 'react';
import { Button } from 'reactstrap';
import './MainColumn.css';
import BlogPost from './BlogPost.js';

import Paginator from './Paginator.js'
import Services from './Services.js'
import Contacts from './Contacts.js'
import About from './About.js'
import AdminLogin from './AdminLogin.js'
import ManageUsers from './ManageUsers.js'

import PrivacyPolicy from './PrivacyPolicy.js';
import TermsOfService from './TermsOfService.js';

const addButtonStyle = {
    margin: '4px'
    }

const POSTS_PER_PAGE = 5;

class MainColumn extends Component {
    constructor(props){
        super(props)
        this.state = {postList: {},
                      backend: props.backend,
                      adding_new: false,
                      pageCount: 0,
                      currentPage: 1}
        this.setPostData = this.setPostData.bind(this);
        this.queryPostList = this.queryPostList.bind(this);
        this.addNewPost = this.addNewPost.bind(this);
        this.switchPage = this.switchPage.bind(this);
        this.queryPostList();
    }

    queryPostList(){
        if (this.props.sitePage=="home")
            fetch(this.state.backend+"/news_posts/"+((this.state.currentPage-1)*POSTS_PER_PAGE))
                .then(x => x.json()
                .then(x => this.setPostData(x)))
                .catch(() => this.forceRelog());
        if (this.props.sitePage=="projects")
            fetch(this.state.backend+"/projects/"+((this.state.currentPage-1)*POSTS_PER_PAGE))
                .then(x => x.json()
                .then(x => this.setPostData(x)))
                .catch(() => this.forceRelog());
    }

    setPostData(x){
        //x.posts.reverse()
        this.setState({postList: x.posts,
                       pageCount: Math.floor((x.total_count-1)/POSTS_PER_PAGE)+1,
                       adding_new: false})
    }

    handlePageClick(){

        }

    switchPage(newPage){
        this.setState({currentPage: newPage}, this.queryPostList)
    }

    addNewPost(){
        console.log(this.state.postList)
        var newList = this.state.postList.slice();
        newList.unshift({markdown: "", datetime:"", _id: "new", last_edited:'never'});
        this.setState({postList: newList,
                       adding_new: true})
    }

    render(){
        console.log(this.state.postList)

        if (this.props.adminMode && this.props.authToken == '')
            return (<div className="MainColumn">
                        <AdminLogin attemptLogin={this.props.attemptLogin}/>
                    </div>);

        if (this.props.sitePage=="home")
            return (
                <div className="MainColumn">
                    <div className="pageHeader">
                      <h1>Our news</h1>
                    </div>
                    {(this.props.adminMode && !this.state.adding_new) && <Button color="info" onClick={this.addNewPost} style={addButtonStyle}>Add new post</Button>}
                    {Object.keys(this.state.postList).map(x => <BlogPost backend={this.props.backend}
                                                                         adminMode={this.props.adminMode}
                                                                         key={this.state.postList[x]._id+'-'+this.state.postList[x].last_edited}
                                                                         id={this.state.postList[x]._id}
                                                                         markdown={this.state.postList[x].markdown}
                                                                         datetime={this.state.postList[x].datetime}
                                                                         onUpdateList={this.queryPostList}
                                                                         authToken={this.props.authToken}
                                                                         type="news_post"
                                                                         forceRelog={this.props.forceRelog}/>)}
                    {(this.state.pageCount > 1) && <Paginator switchPage={this.switchPage} currentPage={this.state.currentPage} pageCount={this.state.pageCount}/>}
                </div>);
        if (this.props.sitePage=="projects")
            return (
                <div className="MainColumn">
                    <div className="pageHeader">
                      <h1>Our previous projects</h1>
                    </div>
                    {(this.props.adminMode && !this.state.adding_new) && <Button color="info" onClick={this.addNewPost} style={addButtonStyle}>Add new post</Button>}
                    {Object.keys(this.state.postList).map(x => <BlogPost backend={this.props.backend}
                                                                         adminMode={this.props.adminMode}
                                                                         key={this.state.postList[x]._id+'-'+this.state.postList[x].last_edited}
                                                                         id={this.state.postList[x]._id}
                                                                         markdown={this.state.postList[x].markdown}
                                                                         datetime={this.state.postList[x].datetime}
                                                                         onUpdateList={this.queryPostList}
                                                                         authToken={this.props.authToken}
                                                                         type="project"
                                                                         forceRelog={this.props.forceRelog}/>)}
                    {(this.state.pageCount > 1) && <Paginator switchPage={this.switchPage} currentPage={this.state.currentPage} pageCount={this.state.pageCount}/>}
                </div>);
        if (this.props.sitePage=="services")
            return (<div className="MainColumn"><Services backend={this.props.backend}/></div>)
        if (this.props.sitePage=="about")
            return (<div className="MainColumn"><About/></div>)
        if (this.props.sitePage=="contacts")
            return (<div className="MainColumn"><Contacts backend={this.props.backend}/></div>)
        if (this.props.sitePage=="privacypolicy")
            return (<div className="MainColumn"><PrivacyPolicy/></div>)
        if (this.props.sitePage=="termsofservice")
            return (<div className="MainColumn"><TermsOfService/></div>)
        if (this.props.sitePage=="manage_users")
            return (<div className="MainColumn"><ManageUsers forceRelog={this.props.forceRelog}
                                                             authToken={this.props.authToken}
                                                             backend={this.props.backend}/></div>)

        return (<div className="MainColumn"></div>)
    }
}

export default MainColumn;
