import React, {Component} from 'react';
import { Button, Form, FormGroup, Label, Input } from 'reactstrap';

class AdminLogin extends Component {
    constructor(props){
        super(props)
        this.state = {login: '',
                      password: ''}
        this.loginChanged = this.loginChanged.bind(this)
        this.passwordChanged = this.passwordChanged.bind(this)
    }

    loginChanged(e) {
        this.setState({login: e.target.value})
        e.preventDefault()
    }

    passwordChanged(e) {
        this.setState({password: e.target.value})
        e.preventDefault()
    }

    render (){
        return (
            <div>
                <Form style={{border: 'solid 2px var(--shadow)', borderRadius: '3px', padding:'4px'}}>
                    <FormGroup>
                      <Label for="login">Login</Label>
                      <Input type="text"
                             name="login"
                             id="login"
                             placeholder="login"
                             onChange={this.loginChanged}
                             onKeyPress={(e => {
                                if (e.charCode == 13) {
                                    this.props.attemptLogin(this.state.login, this.state.password)
                                    e.preventDefault();
                                    e.stopPropagation();
                                    }
                                })}/>
                    </FormGroup>
                    <FormGroup>
                      <Label for="password">Password</Label>
                      <Input type="password"
                             name="password"
                             id="password"
                             placeholder="password"
                             onChange={this.passwordChanged}
                             onKeyPress={(e => {
                                if (e.charCode == 13) {
                                    this.props.attemptLogin(this.state.login, this.state.password)
                                    e.preventDefault();
                                    e.stopPropagation();
                                    }
                                })}/>
                    </FormGroup>
                    <Button color="info"
                            onClick={() => this.props.attemptLogin(this.state.login, this.state.password)
                            }
                            >
                        Login
                    </Button>
             </Form>
            </div>
        );
    }

}

export default AdminLogin;
