import React, {Component} from 'react';
import './Paginator.css'
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap';

class Paginator extends Component {
    constructor(props){
        super()
    }

    getPagesToLink(currentPage, totalCount){
        var linklist = []
        if (currentPage > 6)
           {
               linklist.push(1)
               linklist.push(2)
               linklist.push(3)
               linklist.push('ellipsis-before')
               linklist.push(currentPage-2)
               linklist.push(currentPage-1)
               linklist.push(currentPage)
           }
        else
           {
               for (var i = 1; i <= currentPage; i++)
                   linklist.push(i)
           }
        if (currentPage < totalCount-5)
           {
               linklist.push(currentPage+1)
               linklist.push(currentPage+2)
               linklist.push('ellipsis-after')
               linklist.push(totalCount-2)
               linklist.push(totalCount-1)
               linklist.push(totalCount)
           }
        else
           {
               for (var i = currentPage+1; i <= totalCount; i++)
                   linklist.push(i)
           }
        return linklist
    }

    render (){
        return (
            <div>
              <Pagination className="paginationContainer" aria-label="More news">
                  <PaginationItem disabled>
                      <PaginationLink first key="first" onClick={()=>this.props.switchPage(1)}/>
                    </PaginationItem>
                    <PaginationItem disabled>
                      <PaginationLink previous key="previous" onClick={()=>this.props.switchPage(this.props.currentPage - 1)} />
                    </PaginationItem>
                    {
                        this.getPagesToLink(this.props.currentPage,this.props.pageCount).map(el =>{
                        var isActive = ((el==this.props.currentPage)?'active':'');

                        if (isActive)
                                return (<PaginationItem active >
                                  <PaginationLink key={el}>
                                    {el}
                                  </PaginationLink>
                                </PaginationItem>  );
                        if (!isActive)
                            if (typeof(el)=="string")
                                return (
                                    <PaginationItem>
                                      <PaginationLink key={el}>
                                        ...
                                      </PaginationLink>
                                    </PaginationItem>  );
                            else
                                return (
                                    <PaginationItem>
                                      <PaginationLink key={el} onClick={()=>this.props.switchPage(el)}>
                                        {el}
                                      </PaginationLink>
                                    </PaginationItem>  );

                        })
                    }

                    <PaginationItem>
                      <PaginationLink next key="next" onClick={()=>this.props.switchPage(this.props.currentPage + 1)}/>
                    </PaginationItem>
                    <PaginationItem>
                      <PaginationLink last key="last" onClick={()=>this.props.switchPage(this.props.pageCount)}/>
                    </PaginationItem>
                </Pagination>
            </div>
        );
    }

}

export default Paginator;
