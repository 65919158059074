import React, {Component} from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Label, Input } from 'reactstrap';

class ManageUsers extends Component {
    constructor(props){
        super(props)
        this.state = {passwordIsInputShown: false,
					  userIsInputShown: false,
                      editedUser: '',
                      accountList: [],
                      login: '',
                      password: '',
                      passwordConf: '',
                      canChange: false}
        this.closeModal = this.closeModal.bind(this)
        this.loginChanged = this.loginChanged.bind(this)
        this.passwordChanged = this.passwordChanged.bind(this)
        this.passwordConfChanged = this.passwordConfChanged.bind(this)
        //'Content-Type': 'application/json',
        //,body: JSON.stringify({markdown: value})
        fetch(props.backend+'/user',
              {method: 'GET',
               headers: {
                  'Accept': 'application/json',
                  'x-authentification-token': props.authToken
                    }
                })
            .then( (x) => x.json())
            .then( (x) => this.setState({accountList: x}))

    }

    loginChanged(e){
        this.setState({login: e.target.value,
                       canChange: true})
    }

    passwordChanged(e){
        this.setState({password: e.target.value,
                       canChange: (this.state.password != this.state.passwordConf)})
    }

    passwordConfChanged(e){
        this.setState({passwordConf: e.target.value,
                       canChange: (this.state.password != this.state.passwordConf)})
    }

    closeModal(){
        this.setState({passwordIsInputShown: false,
					   userIsInputShown: false})
    }

    render (){
        return (
            <div>
              <h1>Currently active accounts:</h1>              
              <div style={{'display':'flex','flexDirection':'column'}}>
              {
                this.state.accountList.map(el =>
							<div style={{'display':'flex','flexDirection':'row','justify-content':'space-between', 'width':'100%'}}>
							<div>
							<h2>{el.login}</h2>                            
                            </div>
                            <div>
                            { /*Use closure to preserve loop context*/ }
                            <Button key={el.login} onClick={((el)=>{var _el = el;
                                                return () => this.setState({editedUser: _el, passwordIsInputShown: true});
                                                 })(el.login)
                                            } style={{'margin':'3px'}}>Change password</Button>
                            <Button key={el.login} onClick={()=>
								fetch(this.props.backend+'/user',
                                      {method: 'DELETE',
                                       headers: {
                                          'Accept': 'application/json',
                                          'Content-Type': 'application/json',
                                          'x-authentification-token': this.props.authToken
                                            },
                                        body: JSON.stringify({login: this.state.login})
                                        })								
								} style={{'margin':'3px'}}>Delete</Button>
                             </div>
                             </div>)
              }
              </div>              
              <Button onClick={()=>this.setState({userIsInputShown: true})}>Add new user</Button>
              <Modal isOpen={this.state.userIsInputShown} toggle={this.closeModal} className={this.props.className}>
                  <ModalHeader toggle={this.closeModal}>Enter login and apssword for new account</ModalHeader>
                    <div>
                        <Form style={{border: 'solid 2px var(--shadow)', borderRadius: '3px', padding:'4px'}}>
							<FormGroup>
                              <Label for="login">Login</Label>
                              <Input type="login"
                                     name="login"
                                     id="login"
                                     placeholder="login"
                                     onChange={this.loginChanged}/>
                            </FormGroup>
                            <FormGroup>
                              <Label for="password">Password</Label>
                              <Input type="password"
                                     name="password"
                                     id="password"
                                     placeholder="password"
                                     onChange={this.passwordChanged}/>
                            </FormGroup>        
                     </Form>
                    </div>
                  <ModalFooter>
                    <Button color="info"
                            disabled={!this.state.canChange}
                            onClick={() => {
                                fetch(this.props.backend+'/user',
                                      {method: 'PUT',
                                       headers: {
                                          'Accept': 'application/json',
                                          'Content-Type': 'application/json',
                                          'x-authentification-token': this.props.authToken
                                            },
                                        body: JSON.stringify({login: this.state.login,
                                                              password: this.state.password})
                                        })
                                    .then( (x) => this.closeModal())
                                        }}>
                                Change Password
                        </Button>{' '}
                    <Button color="secondary" onClick={this.closeModal}>Cancel</Button>
              </ModalFooter>
              </Modal>
              <Modal isOpen={this.state.passwordIsInputShown} toggle={this.closeModal} className={this.props.className}>
                  <ModalHeader toggle={this.closeModal}>Set password for {this.state.editedUser}</ModalHeader>
                    <div>
                        <Form style={{border: 'solid 2px var(--shadow)', borderRadius: '3px', padding:'4px'}}>
                            <FormGroup>
                              <Label for="password">Password</Label>
                              <Input type="password"
                                     name="password"
                                     id="password"
                                     placeholder="password"
                                     onChange={this.passwordChanged}/>
                            </FormGroup>
                            <FormGroup>
                              <Label for="passwordConf">Password Confirmation</Label>
                              <Input type="password"
                                     name="passwordConf"
                                     id="passwordConf"
                                     placeholder="password confirmation"
                                     onChange={this.passwordConfChanged}/>
                            </FormGroup>

                     </Form>
                    </div>
                  <ModalFooter>
                    <Button color="info"
                            disabled={!this.state.canChange}
                            onClick={() => {
                                fetch(this.props.backend+'/user',
                                      {method: 'PUT',
                                       headers: {
                                          'Accept': 'application/json',
                                          'Content-Type': 'application/json',
                                          'x-authentification-token': this.props.authToken
                                            },
                                        body: JSON.stringify({login: this.state.editedUser,
                                                              password: this.state.password})
                                        })
                                    .then( (x) => this.closeModal())
                                        }}>
                                Change Password
                        </Button>{' '}
                    <Button color="secondary" onClick={this.closeModal}>Cancel</Button>
                  </ModalFooter>
              </Modal>
            </div>
        );
    }

}

export default ManageUsers;
