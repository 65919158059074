import React, {Component} from 'react';
import './App.css';
import { } from 'reactstrap';
import Menu from './Menu.js'
import MainColumn from './MainColumn.js'
import pterippiLogo from './pterippi-logo.png';
//import background_video from './video/Motion_Background_Loop.m4v';
import background_static from './img/pexels-backgrounds-blank-blue.jpg'

class App extends Component {
  constructor(props){
      super(props)
      this.state = {adminMode: (window.location.search == "?admin"),
                    backend: "https://pterippi.fi/v1",
                    authToken: '',
                    sitePage: "home"};
      this.forceRelog = this.forceRelog.bind(this);
      }

  attemptLogin(self, login, password) {
        (fetch(this.state.backend+"/authenticate/"+login+"/"+password)
        .then(x=>x.json())
        .then(x=>this.setState({authToken: x.authentification_token || ''}))
        .catch(()=>this.forceRelog())
        )
  }

  forceRelog() {
      this.setState({authToken: ''})
  }

  updateSitePage(self, x){
      self.setState({sitePage: x})
      console.log(x)
  }

  render () {
      console.log(this.state.sitePage)
      return (
        <div className="App">
          <header className="App-header">
            <img src={pterippiLogo} className="App-logo" alt="logo" />
          </header>
          <div className="App-body">
          <Menu isAdmin={(this.state.authToken!='')}
                updateSitePage={(x) => this.updateSitePage(this, x) }> xxx </Menu>
          <MainColumn key={this.state.sitePage}
                      sitePage={this.state.sitePage}
                      authToken={this.state.authToken}
                      backend={this.state.backend}
                      adminMode={this.state.adminMode}
                      attemptLogin={(l,p) => this.attemptLogin(this, l, p)}
                      forceRelog={this.forceRelog}/>
           {/*<video className='bgVideoLoop' autoPlay loop muted>
				<source src={background_video} type='video/mp4' />
		   </video>*/}
		   <img src={background_static} className='bgVideoLoop'/>
          </div>
          <footer className="App-footer">
           Copyright Pterippi Oy {new Date().getFullYear()}. <br/>
           Design: Oxana Saloshina and Aleksei Romanenko <br/>
           Programming: Aleksei Romanenko. <br/>
           <div width="100%" className="d-flex flex-wrap align-items-center justify-content-center">
           <div className="col-12 col-sm-2">
			<a onClick={(()=>{this.setState({sitePage: "termsofservice"});})}>Terms of Service</a>
           </div>
           <div className="col-12 col-sm-2">
            <a onClick={(()=>{this.setState({sitePage: "privacypolicy"});})}>Privacy Policy</a>
           </div>
           </div>
           {/*<a href="http://www.videezy.com">Free Stock Video by Videezy.com</a> */}
           <p className="text-muted mb-0">LinkedIn, the LinkedIn logo, the IN logo and InMail are registered trademarks or trademarks of LinkedIn Corporation and its affiliates in the United States and/or other countries.</p>
          </footer>
        </div>
        );
    }

}

export default App;

