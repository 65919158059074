import React, {Component} from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Label, Input } from 'reactstrap';
import PrivacyPolicy from './PrivacyPolicy.js';
const openpgp = require('openpgp')

const recepientPGPKey = `-----BEGIN PGP PUBLIC KEY BLOCK-----

mQGNBF1HmkEBDACwwoAloiSvZ9iwPsfRu4ws4Ib8ec5YQeWIbdBIcWn9rIJmW2rq
pn7jB+G7Fs11pseK4Q1y+JRp1ZdAfVOy/0H8wizJ699MwMmVpPVduAC/1AYMoQBw
mgbGyfDhwDSIOCpJRA/YjTrD1wFg2WyRD5LYxQfOHn85jWFFBEUnN6vdU+DZCaiw
ZHWGIPF7r3l3eTM/MDLh/l6sS3peH44iIBzL55dCpVh/Ad7RDONDX4kFWs/D5TyB
s6Lk8gAFx5PskgdwdIy9lSgufNUj5Mh4aGi/f4QhSBYog+4CLBYMSozNZOL5Dfys
wlOf/XPxnmmzMhWsoDr0HfQ8QXMkSD9uugVrmlgHPU3ThWlUsEwkDr0FXJX1cOyF
ju+12sxVNLIF7X/UYDyOFPDlpQPswAXBVp6r7wya4Z56dINx+JGgU6h76EZHa3Fb
bv4p0K6k4e4j+RtqnRSQ+teXadPC/DdZpN3kFimZEREuZK8vk/C5D4mhb5pKjqoF
DPYZYLQO3fjYp8sAEQEAAbQxQWxla3NlaSBSb21hbmVua28gPGFsZWtzZWkucm9t
YW5lbmtvQHB0ZXJpcHBpLmZpPokBsAQTAQoAGgQLCQgHAhUKAhYBAhkBBYJdR5pB
Ap4BApsDAAoJEHTIZ+yK405LdNcMAJUMHDAQiDDjilmn6f1BZ1Jd6pUFW1s+LTct
0fs6MEq8r8WUbP1K8y7jPvVVsQUoRDb7PbzSGXOgmx/0OOHfDoYqt/gsMloQrat4
9b6fhTNj9IQlsvEUUMlbnc5BjiGYDcc5xQLiG26nBckgQ5S0puzTzDYoqjoEDQe7
zIKXeQinxl/i/BAX31xIFnH/FdfCvJJIrxoqxZ3BheJYa35AC6CrJL5+fjSAW+QG
C8g8I3peGXme8ydghuiv3+Wmt0yTBYNjfw/VB/FzJ7OQt6F8R3FCgzeHF7DZ7PtH
mG1BEOaidqZYXwU0+bbKzHPCgXP4W0R6NfAZX0dB7rsmHOoWrHQJc1H+O32BYQNX
v55SuwAYhGhDBlL1geHp71LRP/HuK5bCS0au1alL60s9gqdShH185L+6lCVzF79l
LyOIX4x0wATao52P7PvuJGyPMqme3Tywvw6HzwKSKnDD+352VQB7xojnIMKgowzL
IuwStuJvBuK6eMJ/CiCV5g4H+ArIYLkBjQRdR5pBAQwAwoRNjjyL7IhVAxT4IoK6
VohWKxh//zjwtwW96HeeiRSH3cTL6I9YtGhkPynfa3FIcnGxiTEtQtxn932vgyix
BPoTckHnxEnw4+AYfgkp961t9y4bWeZpPHopmEhy6Nw/rimchYHguww9V/oGGZ+c
0Wx/G1YlQZvnYqVBwvUCZFZAlJ1KYKTPxGbyWd1DfSnHJMeYkvOVnJSH5XFWdP/q
WyYdWq8V/5uFgbiMOla9KLXr6j0Mq1hilNhx1MWRMscPMbJAUbhBvTOB/I2jCV6D
nRo0/25F8a9RJJBpnfbjTNWXrwCyLXPXWEPGfVYUzR/jeD8VXbh4Yadz48lQoVMt
1Kf2yg/e0UqHapqAvgNoD3uR9mUfSjsMBOaWV1xdxAsQsqnwUcHapS1v4a1VTjcS
3HuHJa4xiPxOvwmZDpmLcpweJV35+hRMx7oC0fkjWbP9tk4LpAX4U/dqpH/pke5i
+d2RnX68MImQcZhkHYAy3YR4mzjJqQCl+dEE4MDdqHvjABEBAAGJAZ8EGAEKAAkF
gl1HmkECmwwACgkQdMhn7IrjTksS/gv/Um1dhbfxF4d78+Le9z+nqt9xHGlZjNeh
xGOEZtycyka+nS1UUqobMK4Kj+HbZ9ZrqNnjeXef9P0piTixDjwiGbdSAU4zCtnj
hKB5VDWx2vJS7LgkUMkJLTvZiHpfyzcc26uzXye1tWLgiPDSDmTIqCMRFkiBbAVL
QlDK3TWU5tR2b5hMs1qT12cA5tAp+wOwUE6olUj1wcGMCdaL5XFotPtAhCDOwBfl
2TEerG2VtuUXaChpY/kVKJaj4IbJGhChDheffZUCGMt+33cOY1TBo9QpowXg7gqn
tONkEw/k4XREswCKv7NYtdFKhADby6ub7pQ9tVTf4T7j2wrzLMVM01kZqsyKb+AP
008ZnnwbYMc5BiKCR5Hrk9GYn+d0A/dJQEeR0/49P1XScqto+u2pGKWjjqbE+SZA
uyVYGDvK/5VlXilx/BMNTy7vahKjkuhNSFpezKzH3JSduimj9awo2105/J+DsHH3
2DHnBSk4BqRatFDS/iWpYsNY9W5tZ4mH
=qoRZ
-----END PGP PUBLIC KEY BLOCK-----`;


openpgp.initWorker({ path:'openpgp.worker.js' }) 

async function encrpytMessage(message) {
	const options = {
		message: openpgp.message.fromText(message),       // input as Message object
		publicKeys: (await openpgp.key.readArmored(recepientPGPKey)).keys//, // for encryption
		//privateKeys: [privKeyObj]                                 // for signing (optional)
	}
	
	return openpgp.encrypt(options).then(ciphertext => {
		var encrypted = ciphertext.data // '-----BEGIN PGP MESSAGE ... END PGP MESSAGE-----'
		return encrypted
	})		
}



class ContactForm extends Component {
    constructor(props){
        super(props)
        this.state = {editedUser: '',
                      name: '',
                      email: '',
                      message: '',
                      service: props.service,
                      company: '',
                      canChange: false,
                      useE2ee: false,
                      pubkey: '',
                      privacyPolicyAccepted: false,
                      messageSent: false}
        this.closeModal = this.closeModal.bind(this)
        this.nameChanged = this.nameChanged.bind(this)
        this.emailChanged = this.emailChanged.bind(this)
        this.messageChanged = this.messageChanged.bind(this)
        this.companyChanged = this.companyChanged.bind(this)
        this.pubkeyChanged = this.pubkeyChanged.bind(this)
        this.useE2eeChanged = this.useE2eeChanged.bind(this)
    }

    useE2eeChanged(e){
        this.setState({useE2ee: e.target.checked})
    }
    
    pubkeyChanged(e){
        this.setState({pubkey: e.target.value})
    }

    nameChanged(e){
        this.setState({name: e.target.value})
    }

    emailChanged(e){
        this.setState({email: e.target.value})
    }

    messageChanged(e){
        this.setState({message: e.target.value})
    }

    companyChanged(e){
        this.setState({company: e.target.value})
    }

    closeModal(){
        this.setState({contactFormIsShown: false})
    }
    


    render (){
        return (
            <div>
              <Modal isOpen={this.props.contactFormIsShown} toggle={this.props.onClose}>
              { !this.state.privacyPolicyAccepted && (<div>
					<ModalHeader toggle={this.props.onClose}>Please review and accept our privacy policy.</ModalHeader>
						<div className="pl-3 pr-3">
							<PrivacyPolicy/>
						</div>
					  <ModalFooter>
						<Button color="info"
								onClick={() => {this.setState({privacyPolicyAccepted: true})}}>
									Accept Privacy Policy
							</Button>{' '}
						<Button color="secondary" onClick={this.props.onClose}>Cancel</Button>
				  </ModalFooter>
				  </div>)
			  }
              { (this.state.privacyPolicyAccepted && !this.state.messageSent) && (<div>
					<ModalHeader >Contact and request details.</ModalHeader> {/*toggle={this.props.onClose}*/}
						<div className="text-muted ml-3 mr-3 mt-2 mb-2">
							Data sent via this form is encrypted in your 
							browser and can only be decrypted using our private key. 
							This is the state-of-the-art approach to protection of your 
							privacy and minimization of damage, caused by data breaches.
							You can read more about this technology at <a href="https://www.openpgp.org/">https://www.openpgp.org/</a>
						</div>
						<div className="pl-3 pr-3">
						{/*style={{border: 'solid 2px var(--shadow)', borderRadius: '3px', padding:'4px'}}*/}
							<Form >
								<FormGroup>
								  <Label for="name">Name</Label>
								  <Input type="text"
										 name="name"
										 id="name"
										 placeholder="John"
										 onChange={this.nameChanged}/>
								</FormGroup>
								<FormGroup>
								  <Label for="company">Company name</Label>
								  <Input type="text"
										 name="company"
										 id="company"
										 placeholder="ACME LLC"
										 onChange={this.companyChanged}/>
								</FormGroup>        
								<FormGroup>
								  <Label for="email">E-mail</Label>
								  <Input type="email"
										 name="email"
										 id="email"
										 placeholder="john@mailserver.org"
										 onChange={this.emailChanged} required/>
								</FormGroup>        
								<FormGroup>
								  <Label for="message">Message</Label><br/>
								  <textarea name="message"
										 id="message"
										 width="100%"
										 placeholder="Hi. I would like to get a quotaton."
										 onChange={this.messageChanged}
										 className='w-100'/>
								</FormGroup>  
								<FormGroup className="d-flex align-items-start">								  
								  <input name="useE2ee"
									     type="checkbox"
										 id="useE2ee"
										 width="100%"
										 placeholder="Hi. I would like to get a quotaton."
										 onChange={this.useE2eeChanged}
										 className='mt-1'/><Label for="useE2ee" className="ml-2 flex-grow-1">I would like to get response encrypted with PGP</Label>
								</FormGroup>  
								<FormGroup hidden={!this.state.useE2ee}>
								  <Label for="pubkey">Please provide your OpenPGP public key</Label><br/>
								  <textarea name="pubkey"
										 id="pubkey"
										 width="100%"
										 placeholder="-----BEGIN PGP PUBLIC KEY BLOCK-----"
										 onChange={this.pubkeyChanged}
										 className='w-100'/>
								</FormGroup>        
						 </Form>
						</div>
					  <ModalFooter>
						<Button color="info"
								onClick={() => {
									(encrpytMessage(JSON.stringify({name: this.state.name,
													 			   company: this.state.company,
													   			   email: this.state.email,
																   message: this.state.message,
																   service: this.state.service,
																   pubkey: this.state.pubkey,
																   useE2ee: this.state.useE2ee}))
													.then((encrypted)=>{
														fetch(this.props.backend+'/mailbox/contact_form',
															  {method: 'POST',
															   headers: {
																  'Accept': 'application/json',
																  'Content-Type': 'application/json'
																  },
																body: JSON.stringify({'encrypted_message': encrypted})
																})
															.then( (x) => this.setState({messageSent: true}))
														}));											 
												}}>
									Send
							</Button>{' '}
						<Button color="secondary" onClick={this.props.onClose}>Cancel</Button>
				  </ModalFooter>
				  </div>)
			  }
			  { this.state.messageSent && (<div>
					<ModalHeader toggle={()=>{this.setState({messageSent: false});
											  this.props.onClose()}}>Thank you for your message.</ModalHeader>
						<div className="pl-3 pr-3">
							We will contact you soon.
						</div>
					  <ModalFooter>
						<Button color="primary" onClick={()=>{this.setState({messageSent: false});
																this.props.onClose()}}>Close</Button>
				  </ModalFooter>
				  </div>)
			  }
              </Modal>              
            </div>
        );
    }

}

export default ContactForm;
