import React, {Component} from 'react';
import './Services.css';
import { Card, CardImg, CardText, CardBody,
  CardTitle, CardSubtitle, Button } from 'reactstrap';
import services_programming_pic from './img/services-programming.jpg';
import services_pcb_pic from './img/services-pcb.jpg';
import services_iot_pic from './img/services-iot.png';
import services_translation_pic from './img/services-translation.jpg';
import services_writing_pic from './img/services-writing.jpg';
import services_bigdata_pic from './img/services-big-data.jpg';
import services_web_pic from './img/services-web.jpg';

import ContactForm from './ContactForm.js'



//<CardSubtitle>{props.serviceSubtitle}</CardSubtitle>
const ServiceCard = (props) => {
  return (
	  <div className="col-12 col-sm-6 col-md-6 col-lg-6 pb-1 pl-1 pr-1 ">
		  <Card className="pt-1 pl-1 pr-1 d-flex flex-column h-100">
			<CardImg width="100%" src={props.serviceMedia} alt={props.serviceTitle}/>
			<CardBody className="pt-0 pb-px-0 flex-grow-1 d-flex flex-column justify-content-between">
			  <CardTitle className="mt-1"><h4>{props.serviceTitle}</h4></CardTitle>          
			  <CardText className="text-left">{props.children}</CardText>
			  <Button onClick={props.onClick} backend={props.backend}>Contact us</Button>
			</CardBody>
		  </Card>
      </div>
  );
};

class Services extends Component {
    constructor(props){
        super()
        this.state = {contactFormIsShown: false,
					  selectedService: ''}
    }

    render(){
        return (<div>
            <h1>Here is what we can offer you</h1>
            <div className="serviceList d-flex flex-wrap align-items-stretch">
            <ServiceCard serviceMedia={services_translation_pic} 
						 serviceTitle="Technical Translation and proofreading"
						 backend={this.props.backend}
						 onClick={()=>{this.setState({selectedService:'Technical Translation and proofreading', contactFormIsShown: true})}}>
				
						<li>English {'<->'} Russian general and technical translation</li>
						<li>English proofreading</li>
						<li>Russian proofreading</li>
	
            </ServiceCard>
            <ServiceCard serviceMedia={services_writing_pic} 
						 serviceTitle="Article Writing"
						 backend={this.props.backend}
						 onClick={()=>{this.setState({selectedService:'Article Writing', contactFormIsShown: true})}}>
				
						<li>Technical Articles in English or Russian</li>
						<li>With expertise on cybersecurity, engineering and scientific method</li>
	
            </ServiceCard>
            <ServiceCard serviceMedia={services_bigdata_pic} 
						 serviceTitle="Big Data Analysis"
						 backend={this.props.backend}
						 onClick={()=>{this.setState({selectedService:'Big Data Analysis', contactFormIsShown: true})}}>
			
						<li>Statistical analysis</li>
						<li>Mathematical model building and fitting</li>
						<li>Machine learning</li>
				
            </ServiceCard>
            <ServiceCard serviceMedia={services_programming_pic} 
						 serviceTitle="PC Software Development"
						 backend={this.props.backend}
						 onClick={()=>{this.setState({selectedService:'PC Software Development', contactFormIsShown: true})}}>
		
						<li>C++ (Qt)</li>
						<li>JS (React/Electron) with optional Python backend.</li>
						<li>C# (UnityEngine)</li>
			
            </ServiceCard>
            <ServiceCard serviceMedia={services_web_pic} 
						 serviceTitle="Web Software Development"
						 backend={this.props.backend}
						 onClick={()=>{this.setState({selectedService:'Web Software Development', contactFormIsShown: true})}}>
			
						<li>Frontend developement and bugfixes: Pure Javascript or ReactJS</li>
						<li>Backend developement: Python/Flask, NodeJS</li>
						<li>Databases: MongoDB, MariaDB, Postgres, Logstash, InfluxDB</li>
				
            </ServiceCard>
            <ServiceCard serviceMedia={services_iot_pic} 
						 serviceTitle="Embedded Software Development"
						 backend={this.props.backend}
						 onClick={()=>{this.setState({selectedService:'Embedded Software Development', contactFormIsShown: true})}}>
						<li>Programming for Atmel and Texas Instruments MCUs.</li>
						<li>IoT and Smart grid system integrations: MQTT, IEC104.</li>
						<li>Wired and wireless connectivity: W5500, ZigBee, NRF24L01.</li>				
            </ServiceCard>            
            <ServiceCard serviceMedia={services_pcb_pic} 
						 serviceTitle="Electronics Design"
						 backend={this.props.backend}
						 onClick={()=>{this.setState({selectedService:'Electronics Design', contactFormIsShown: true})}}>
						<li>PCB layout design, routing, ordering and verification.</li>
						<li>Enclosure design and prototyping: STL, OpenSCAD or FreeCAD models</li>
            </ServiceCard>
            <ContactForm contactFormIsShown={this.state.contactFormIsShown}
						 backend={this.props.backend}
						 service={this.state.selectedService}
						 key={this.state.selectedService}
						 onClose={()=>{this.setState({contactFormIsShown: false})}}/>
            </div>
            </div>)

    }
}

export default Services;
