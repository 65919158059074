import React, { useState } from 'react';
import { Button } from 'reactstrap';
import './BlogPost.css';
import ReactMde from "react-mde";
import ReactDOM from "react-dom";
import * as Showdown from "showdown";
import "react-mde/lib/styles/css/react-mde-all.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faTrashAlt, faUpload} from '@fortawesome/free-solid-svg-icons'
import { FilePond } from 'react-filepond';
import 'filepond/dist/filepond.min.css';

const converter = new Showdown.Converter({
  tables: true,
  simplifiedAutoLink: true,
  strikethrough: true,
  tasklists: true
});

const faIcon = {
          marginRight: '3px',
        }

const blogButtonStyle = {margin: '3px'}




export default function BlogPost(props) {

    var defaultHtml = converter.makeHtml(props.markdown);
    const [pond, setPond] = React.useState({});
    const [html, setHtml] = React.useState(defaultHtml);
    const [isEditing, setIsEditing] = React.useState(false);
    const [value, setValue] = React.useState(props.markdown);
    const [selectedTab, setSelectedTab] = React.useState("write");

    var isPublished = (props.id != "new");
    return (
        <div className="BlogPost">
            {props.adminMode && (<div>
                                    <Button style={blogButtonStyle} onClick={()=>{
                                              if (isEditing && isPublished) {
                                                    (fetch(props.backend+'/'+props.type+'/'+props.id,
                                                           {method: 'PUT',
                                                            headers: {
                                                              'Accept': 'application/json',
                                                              'Content-Type': 'application/json',
                                                              'x-authentification-token': props.authToken
                                                            },
                                                            body: JSON.stringify({markdown: value,
                                                                                  datetime: props.datetime})
                                                            })
                                                            .then(x=>props.onUpdateList())
                                                            .catch(() => props.forceRelog()))
                                                    }
                                              else {
                                                if (isEditing) setHtml(converter.makeHtml(value));
                                                setIsEditing(!isEditing);
                                                }
                                              }
                                        } color="warning">
                                        <FontAwesomeIcon icon={faEdit} style={faIcon}/>Edit
                                    </Button>
                                    {isPublished &&
                                    <Button style={blogButtonStyle} onClick={()=>(fetch(props.backend+'/'+props.type+'/'+props.id,
                                                                                       {method: 'DELETE',
                                                                                        headers: {'x-authentification-token': props.authToken}
                                                                                        })
                                                                                        .then(x=>props.onUpdateList())
                                                                                        .catch(() => props.forceRelog()))
                                                                            }
                                            color="danger">
                                        <FontAwesomeIcon icon={faTrashAlt} style={faIcon}/>Delete
                                    </Button>
                                    }
                                    {!isPublished &&
                                    <Button style={blogButtonStyle} onClick={()=>(fetch(props.backend+'/'+props.type+'s',
                                                                                       {method: 'PUT',
                                                                                        headers: {
                                                                                          'Accept': 'application/json',
                                                                                          'Content-Type': 'application/json',
                                                                                          'x-authentification-token': props.authToken
                                                                                        },
                                                                                        body: JSON.stringify({markdown: value})
                                                                                        })
                                                                                        .then(x=>props.onUpdateList())
                                                                                        .catch(() => props.forceRelog()))
                                                                            } color="info">
                                         <FontAwesomeIcon icon={faUpload} style={faIcon}/>Publish
                                    </Button>
                                    }
                                    <hr/>
                                 </div>)}
            {!isEditing &&
                <div>
                    <span dangerouslySetInnerHTML={{__html: html}} />
                    <div className="BlogPostFooter">Published at: {window.Intl.DateTimeFormat(navigator.language).format(Date.parse("2019-07-08T05:19:25.107000"))}</div>
                </div>
            }
            {isEditing &&
              <div><ReactMde
                value={value}
                onChange={setValue}
                selectedTab={selectedTab}
                onTabChange={setSelectedTab}
                generateMarkdownPreview={markdown =>
                  Promise.resolve(converter.makeHtml(markdown))
                }
              />
              <FilePond ref={ref => setPond(ref) } onupdatefiles={(fileItems) => {
                              // Set current file objects to this.state
                              console.log(fileItems)
                              if (fileItems.length > 0)
                                  {
                                      var fr = new FileReader();
                                      fr.onload = function(e) {
                                        // The file's text will be printed here
                                        //console.log(e.target.result)
                                        fetch(props.backend+'/file/'+fileItems[0].filename,
                                           {method: 'PUT',
                                            headers: {
                                              'Accept': 'application/json',
                                              'Content-Type':'application/octet-stream',
                                              'x-authentification-token': props.authToken
                                            },
                                            body: btoa(e.target.result)
                                            })
                                            .then(x=>x.json())
                                            .then(x=>{
                                                setValue(value+'![](/v1/file/'+x.filename+')');
                                                })
                                            .catch(() => props.forceRelog())

                                      };
                                      fr.readAsBinaryString(fileItems[0].file);
                                  }
                              }} />
              </div>}
        </div>
    );

}
