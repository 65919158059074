import React, {Component} from 'react';
import { Card, CardImg, CardText, CardBody,
  CardTitle, CardSubtitle, Button } from 'reactstrap';
import './About.css';
import about_head_pic from './img/about-head-pic.jpg';
import about_head_lpr_pic from './img/about-head-pic-lpr.jpg';
import oxana_pic from './img/staff/oxana.jpeg';
import aleksei_pic from './img/staff/aleksei.jpeg';
import li_logo from './img/3rd-parties/LI-Logo.png';

class About extends Component {
    constructor(props){
        super(props)
        this.state = {}
    }

    render(){
        return (<div className="about-page d-flex flex-wrap align-items-stretch">
        <div className="page-top-pic col-12 pb-1 pl-1 pr-1 ">
          <Card className="pt-1 pl-1 pr-1 d-flex flex-column h-100">
			<CardImg height="100%" src={about_head_lpr_pic} />
			<CardBody className="pt-0 pb-px-0 flex-grow-1 d-flex flex-column justify-content-between">
			  <CardTitle className="mt-1"><h1>Who we are</h1></CardTitle>          
			  <CardText className="text-left">A small family start-up, joining people with broad spectrum of talents,
            that is ready to tailor our services to the best of your needs. Our main areas
            of expertise are translations, proof reading and software development.</CardText>
			</CardBody>
		  </Card>
		 </div>
		 <div className="col-12 col-sm-6 col-md-6 col-lg-6 pb-1 pl-1 pr-1 ">
          <Card className="pt-1 pl-1 pr-1 d-flex flex-column h-100">
			<CardImg height="15vh" src={oxana_pic} />
			<CardBody className="pt-0 pb-px-0 flex-grow-1 d-flex flex-column justify-content-between">
			  <CardTitle className="mt-1"><h3>Oxana Saloshina</h3></CardTitle>          
			  <CardSubtitle>Language Service and Translation Specialist</CardSubtitle>
			  <CardText className="text-muted text-left flex-grow-1"><p>
			        Oxana has been rised as bi-lingual in English and Russian. 
					She has completed here studies in the Field of Robotics in 
					Moscow State University of Instrument 
					Engineering and Computer Science. During her studies and 
					afterwards she had work experience as organizer 
					of international fairs, international procurement manager, 
					assisting translator for personnel of natural gas 
					compression plant and field service engineer for Siemens.</p>
					<p>
					Oxana has experience in control systems engineering, technical translation, 
					proofreading as well as technical writing.
					</p></CardText>
			  <CardSubtitle><Button style={{background: "#eee", color: "#111"}}
									onClick={(()=>{window.location.href="https://www.linkedin.com/in/oxanasaloshina/";})}> See my profile at <img src={li_logo} height="12 em"></img></Button> </CardSubtitle>
			</CardBody>
		  </Card>
		  </div>
		 <div className="col-12 col-sm-6 col-md-6 col-lg-6 pb-1 pl-1 pr-1 ">         
          <Card className="pt-1 pl-1 pr-1 d-flex flex-column h-100">
			<div><CardImg width="100%" src={aleksei_pic} /></div>
			<CardBody className="pt-0 pb-px-0 flex-grow-1 d-flex flex-column justify-content-between">
			  <CardTitle className="mt-1"><h3>Aleksei Romanenko</h3></CardTitle>     			  
			  <CardSubtitle>Full-stack Software Developer</CardSubtitle>
			  <CardText className="text-muted text-left flex-grow-1">					
					<p>
					Aleksei has completed his doctoral degree with 
					Lappeenranta University of Technology, Finland and 
					Graz University of Technology, Austria.
					The scope of his doctoral studies included design of 
					hardware and software for vibrational diagnostic of rotating machinery.
					During his doctoral studies he obtained crucial qualifications in statistical analysis, 
					machine learning, design of electronic devices and programming.</p>
					<p>
					Aleksei's core expertise is software and systems design,
					big data analysis and embedded systems.
					</p> </CardText>
			  <CardSubtitle><Button style={{background: "#eee", color: "#111"}}
									onClick={(()=>{window.location.href="https://www.linkedin.com/in/aleksei-romanenko-51828054/";})}> See my profile at <img src={li_logo} height="12 em"></img></Button> </CardSubtitle>
			  
			</CardBody>
		  </Card>
		  </div>         
            </div>)

    }
}

export default About;
