import React, {Component} from 'react';
import { Card, CardImg, CardText, CardBody,CardFooter,
  CardTitle, CardSubtitle, Button } from 'reactstrap';
import './Contacts.css';
import social_pic from './img/contact-social.jpeg';
import mail_pic from './img/contact-mail.jpg';
import email_pic from './img/contact-email.jpg';
import messenger_pic from './img/contact-messenger.jpeg';

import ContactForm from './ContactForm.js'

class Contacts extends Component {
    constructor(props){
        super()
        this.state = {contactFormIsShown: false}
    }

    render(){
        return (<div className="contacts mt-2 mb-2">
                <h1>Contact us for quotation <br/>or if you have any questions<hr style={{color: 'var(--shadow)'}}/></h1>
				<div className="d-flex flex-wrap align-items-stretch">
					<div className="col-12 col-sm-6 col-md-6 col-lg-6 pb-1 pl-1 pr-1 ">         
					  <Card className="pt-1 pl-1 pr-1 d-flex flex-column h-100">
						<div><CardImg width="100%" src={messenger_pic} /></div>
						<CardBody className="pt-0 pb-px-0 flex-grow-1 d-flex flex-column justify-content-between">
						  <CardTitle className="mt-1"><h2>Messengers</h2></CardTitle> 
						  <CardText className="text-muted text-left flex-grow-1">					
							Telegram:<br/> <a href="https://t.me/joinchat/AAAAAEo8JD4NfYwvE_xj0A">Join our channel</a><br/>
							WhatsApp:<br/> <a href="https://wa.me/358417086870">https://wa.me/358417086870</a>
                         </CardText>						 
						</CardBody>
					  </Card>
					</div>
					<div className="col-12 col-sm-6 col-md-6 col-lg-6 pb-1 pl-1 pr-1 ">         
					  <Card className="pt-1 pl-1 pr-1 d-flex flex-column h-100">
						<div><CardImg width="100%" src={email_pic} /></div>
						<CardBody className="pt-0 pb-px-0 flex-grow-1 d-flex flex-column justify-content-between">
						  <CardTitle className="mt-1"><h2>E-mail</h2></CardTitle> 
						  <CardText className="text-muted text-left flex-grow-1">					
                            <a href={"mailto:info@pterippi.fi"}>info@pterippi.fi</a><br/>
                            name.surname@pterippi.fi                            
                         </CardText>						 
                         <Button onClick={()=>{this.setState({contactFormIsShown: true})}}>Contact us</Button>                         
						</CardBody>
							
					  </Card>
					</div>   
					<div className="col-12 col-sm-6 col-md-6 col-lg-6 pb-1 pl-1 pr-1 ">         
					  <Card className="pt-1 pl-1 pr-1 d-flex flex-column h-100">
						<div><CardImg width="100%" src={social_pic} /></div>
						<CardBody className="pt-0 pb-px-0 flex-grow-1 d-flex flex-column justify-content-between">
						  <CardTitle className="mt-1"><h2>Social Media</h2></CardTitle> 
						  <CardText className="text-muted text-left flex-grow-1">					
							Facebook:<br/> <a href={"https://www.facebook.com/PterippiOy"}>https://www.facebook.com/pterippioy</a><br/>
							Instagram:<br/> <a href={"https://www.instagram.com/pterippioy"}>https://www.instagram.com/pterippioy</a>
                         </CardText>						 
						</CardBody>
					  </Card>
					</div>   
					<div className="col-12 col-sm-6 col-md-6 col-lg-6 pb-1 pl-1 pr-1 ">         
					  <Card className="pt-1 pl-1 pr-1 d-flex flex-column h-100">
						<div><CardImg width="100%" src={mail_pic} /></div>
						<CardBody className="pt-0 pb-px-0 flex-grow-1 d-flex flex-column justify-content-between">
						  <CardTitle className="mt-1"><h2>Mail address</h2></CardTitle> 
						  <CardText className="text-muted text-left flex-grow-1">		
							Lainekatu 5<br/>53920<br/>Lappeenranta<br/>Finland
                         </CardText>						 
						</CardBody>
					  </Card>
					</div>    
				</div>
			    <ContactForm contactFormIsShown={this.state.contactFormIsShown}
						 backend={this.props.backend}
						 service="no information"
						 onClose={()=>{this.setState({contactFormIsShown: false})}}/>
                </div>)

    }
}

export default Contacts;
