import React, {Component} from 'react';
import { Button, } from 'reactstrap';
import './Menu.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHome, faCogs, faSuitcase, faAddressCard, faMailBulk, faUser } from '@fortawesome/free-solid-svg-icons'
import ReactMde from "react-mde";
import * as Showdown from "showdown";

class Menu extends Component {
    menuButton = {
          margin: '2px',
          minWidth: '105cw',
          fontSize: '0.8rem',
          display: 'flex',
          flexWrap: 'no-wrap',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
          whiteSpace: 'nowrap',
        }
    faIcon = {
          marginRight: '3px',
        }
    constructor(props){
         super(props)
         console.log(props)
         this.state = {updateSitePage: props.updateSitePage}
         console.log(this.state)
         }


    render (){
        console.log(this.props)
        return (
          <div className="Menu-container">
              <Button color="primary" style={this.menuButton} onClick={()=>this.state.updateSitePage("home")}>
                <FontAwesomeIcon icon={faHome} style={this.faIcon}/><div>Home</div></Button>
              <Button color="primary" style={this.menuButton} onClick={()=>this.state.updateSitePage("services")}>
                <FontAwesomeIcon icon={faCogs} style={this.faIcon}/><div>Our services</div></Button>
              <Button color="primary" style={this.menuButton} onClick={()=>this.state.updateSitePage("projects")}>
                <FontAwesomeIcon icon={faSuitcase} style={this.faIcon}/><div>Our projects</div></Button>
              <Button color="primary" style={this.menuButton} onClick={()=>this.state.updateSitePage("about")}>
                <FontAwesomeIcon icon={faAddressCard} style={this.faIcon}/><div>About us</div></Button>
              <Button color="primary" style={this.menuButton} onClick={()=>this.state.updateSitePage("contacts")}>
                <FontAwesomeIcon icon={faMailBulk} style={this.faIcon}/><div>Contacts</div></Button>
            {this.props.isAdmin &&
                (<Button color="primary" style={this.menuButton} onClick={()=>this.state.updateSitePage("manage_users")}>
                <FontAwesomeIcon icon={faUser} style={this.faIcon}/><div>Administrators</div></Button>)}
          </div>
          );
      }

}

export default Menu;
